.row-header{
  margin:0px auto;
  padding:0px auto;
}

.row-content {
  margin:0px auto;
  padding: 50px 0px 50px 0px;
  border-bottom: 1px ridge;
  min-height:400px;
}

.footer{
  background-color: #000000;
  color:floralwhite;
  margin:0px auto;
  padding: 10px 0px 0px 0px;
}
.jumbotron {
  padding:70px 30px 70px 30px;
  margin:0px auto;
  background: #9575CD ;
  color:floralwhite;
}

address{
  font-size:80%;
  margin:0px;
  color:#0f0f0f;
}

.navbar-dark {
  background-color: #d12f2f;
  color: white !important;
}



.page-enter {
  opacity: 0.01;
  transform: translateX(-100%);
}

.page-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: all 300ms ease-in;
}

.page-exit {
  opacity: 1;
  transform: translateX(0%);
}

.page-exit-active {
  opacity: 0.01;
  transform: translateX(100%);
  transition: all 300ms ease-out;
}

.itemFormat {
  display: flex;
  margin:0px auto;
  padding:0px auto;
  font-size:100%;
  color: rgb(255, 255, 255);
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: text-bottom;
}